import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "form"];

  open(event) {
    event.preventDefault();
    document.getElementById('modal-code').value = event.currentTarget.dataset.code;
    document.getElementById('modal-version').value = event.currentTarget.dataset.versionId;
    this.formTarget.method = 'patch';
    this.formTarget.action = `vehicles/${event.currentTarget.dataset.code}`;

    this.modalTarget.classList.toggle('hidden');
  }

  close(event) {
    event.preventDefault();
    this.modalTarget.classList.toggle('hidden');
  }
}