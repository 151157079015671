import { Controller } from "@hotwired/stimulus";
import SlimSelect from 'slim-select';

export default class extends Controller {
  connect() {
    new SlimSelect({
      select: this.element,
    });
  }

  change(event) {
    const part_id = event.target.value;
    const vehicle_code = event.target.dataset.code;
    const url = event.target.dataset.url;
    const method = event.target.dataset.method;
    const frame = document.getElementById('subpart-list');
    frame.src = `/vehicles/${vehicle_code}/parts/${part_id}/subpart_list?url=${url}&method=${method}`;
    frame.reload();
  }
}