import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  open = (event) => {
    event.preventDefault();
    const selected_subpart = event.target['dataset']['subpartId'];
    const with_subparts = event.target['dataset']['withSubparts'];

    document.querySelector('#work_form #subpart_id').value = selected_subpart;
    document.querySelector('#work_form #with_subparts').value = with_subparts;

    document.getElementById(event.currentTarget.dataset.id).classList.remove('hidden');
  };

  edit = () => {
    console.log(document.querySelectorAll('#work_form input'));
    document.querySelectorAll('#work_form input').forEach((input) => {
      input.disabled = false;
    });
    document.querySelectorAll('#work_form textarea').forEach((input) => {
      input.disabled = false;
    });
    document.querySelectorAll('#work_form select').forEach((input) => {
      input.disabled = false;
    });
    document.querySelector('#work_form input[type=submit]').classList.remove('hidden');
    document.querySelector('#work_modal .upload-button').classList.add('hidden');
  };

  close = (event) => {
    event.preventDefault();
    document.getElementById(event.currentTarget.dataset.id).classList.add('hidden');
  };

  addToName = (event) => {
    event.preventDefault();
    const replace_to_name = document.querySelector('#subpart-list .part_name').textContent;
    document.querySelector('#to_name').textContent = replace_to_name;
  };
}