window.showChooseFileName = (file_input) => {
  let file_name = file_input.files[0].name;
  let input_name = document.getElementById("file_name");
  input_name.value = file_name;
};


window.changeState = () => {
  document.getElementById('edit-mode').classList.toggle('hidden');
  document.getElementById('view-mode').classList.toggle('hidden');
  const actions_links = document.getElementsByClassName('row-action');

  for (var i = 0; i < actions_links.length; i++) {
    actions_links[i].classList.toggle('hidden');
  }
};