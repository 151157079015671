// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import './partsset';

import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import XHR from '@uppy/xhr-upload';
if (document.getElementById('uppy-dashboard')) {
  new Uppy().use(Dashboard, { inline: true, target: '#uppy-dashboard' })
            .use(XHR, { endpoint: '/image' });
}
